<template>
    <div class="misc-wrapper">
        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1">Under Maintenance 🛠</h2>
                <p>
                    Sorry for the inconvenience but we're performing some
                    maintenance at the moment
                </p>
                <p>--- Leechall.io Team ---</p>
                <b-img fluid :src="imgUrl" alt="Under maintenance page" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg } from "bootstrap-vue";

import store from "@/store/index";

export default {
    components: {
        BImg,
    },
    data() {
        return {
            downImg: require("@/assets/images/pages/under-maintenance.svg"),
        };
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.downImg = require("@/assets/images/pages/under-maintenance-dark.svg");
                return this.downImg;
            }
            return this.downImg;
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
